var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GenericTable',{ref:"lista",attrs:{"name":"","title":"Materiais Sem QR Code","add-route":"materialSemQRCodeAdicionar","edit-route":"materialSemQRCodeEditar","historicoRoute":"materialSemQRCodeHistorico","cols":[
      'Tipo de material',
      'Descrição',
      'Nº de peças' ],"cols-name":[
      'tipoMaterialGenerico',
      'descricao',
      'pecas' ],"cols-map":function (i) { return [
        i.tipoMaterialGenerico,
        i.descricao,
        i.pecas ]; },"route":"materialGenerico","permissionsToWrite":['rw_cadastro_rapido'],"filters":_vm.filters,"idKey":"id_material_generico","hasFotografia":"","hasPagination":"","materiaisGenericos":_vm.materiais,"customSearchHandler":_vm.materialGenericSearch},on:{"open-modal-fotografia":_vm.openModalFotografia},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Descrição"}},[_c('b-form-input',{staticClass:"invision-select-typing w-70",attrs:{"name":"material_generico"},model:{value:(_vm.filters.descricao),callback:function ($$v) {_vm.$set(_vm.filters, "descricao", $$v)},expression:"filters.descricao"}})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Tipo Material"}},[_c('b-form-select',{ref:"selectTipoMaterialGenerico",staticClass:"invision-input mr-1 w-30",attrs:{"name":"tipo_material_generico","options":_vm.tipoMaterialGenericoOptions},model:{value:(_vm.filters.idTipoMaterialGenerico),callback:function ($$v) {_vm.$set(_vm.filters, "idTipoMaterialGenerico", $$v)},expression:"filters.idTipoMaterialGenerico"}},[_c('template',{slot:"first"},[_c('option',{domProps:{"value":null}},[_vm._v(" -- Selecione uma opção -- ")])])],2)],1)],1)],1)]},proxy:true}])}),_c('Modal',{ref:"modalPhoto",attrs:{"id":"modalPhoto","nome":"modalPhoto","title":"Fotos do Material","no-close-on-esc":"","no-close-on-backdrop":"","size":"md","centered":""}},[_c('PhotoView',{attrs:{"options":{modalPhotos: _vm.modalPhotos},"position":"relative","margin":"0 0 0 -1%"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }